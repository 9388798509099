import React, {FC, ReactNode, useContext, useEffect, useState} from "react"
import "./CatalogPage.scss"
import {useLocation, useParams} from "react-router-dom"
import {useCatalog} from "../_services/catalog"
import ky from "ky"
import {TypeCountry, TypeWine} from "../_types/types"
import CartModule from "../_components/Cart/CartModule"
import {CartStateContext} from "../_context/CartContext"
import {API_URL, HAPI_URL} from "../_helpers/environment"
import {encodeBase64} from "../_helpers/encoding"
import {AuthStateContext} from "../_context/AuthContext"
import {toHorecaPrice} from "../_helpers/price"

interface WineListProps {
    idList: string[]
}

const WineList: FC<WineListProps> = ({idList}) => {

    const cart = useContext(CartStateContext)
    const [wines, setWines] = useState<TypeWine[]>([])
    const {hash} = useLocation()

    useEffect(() => {
        setWines([])
        if (idList.length === 0) {
            return
        }
        ky.get(`${API_URL}/documents/wines?page[size]=100&filter[@id]=/${idList.map(s => `${s}$`).join("|")}/`)
            .json<{ "@graph": TypeWine[] }>()
            .then(res => {
                const wines = res["@graph"]
                wines.sort((a, b) => a.name < b.name ? -1 : 1)
                return wines
            })
            .then(res => setWines(res))
            .catch(err => console.log(err))
    }, [idList])

    const entries: ReactNode[] = []

    wines.forEach(wine => {
        let cartCount = 0
        for (let i = 0; i < cart.cart.items.length; i++) {
            const item = cart.cart.items[i]
            if (item.product["@id"] === wine["@id"]) {
                cartCount = item.quantity
            }
        }
        entries.push((
            <tr
                className={`wine-entry ${hash !== "" && wine["@id"].indexOf(hash.substring(1)) === (wine["@id"].length - hash.length + 1) ? "highlighted" : ""}`}
                key={wine["@id"]}
            >
                <td className="entry-padding"/>
                <td className="entry-img">
                    {wine.image?.thumbnail ? (
                        <img alt={"?"} width={22} src={wine.image.thumbnail.contentUrl}/>
                    ) : <div className="no-image">?</div>}
                </td>
                <td className="entry-title">
                    <div className="entry-name">
                        {wine.name}
                    </div>
                    <div className="entry-availability">
                        {wine.offers.availability === "in-stock" ? (
                            <span className={"availability-label status-available"}>
                                In Stock
                            </span>
                        ) : (
                            wine.offers.availabilityStarts ? (
                                <span className={"availability-label status-pending"}>
                                    Out of Stock till {wine.offers.availabilityStarts}
                                </span>
                            ) : (
                                <span className={"availability-label status-unknown"}>
                                    Out of Stock
                                </span>
                            )
                        )}
                    </div>
                    <div className="units-in-cart">
                        &euro;{toHorecaPrice(wine.offers.integerPrice / 100).toFixed(2)}
                        {cartCount !== 0 ? (
                            <span className="added-to-cart">&nbsp;•&nbsp;{cartCount}pc. in cart</span>
                        ) : null}
                    </div>

                </td>
                <td className="entry-price text-right">
                    &euro;{toHorecaPrice(wine.offers.integerPrice / 100).toFixed(2)}
                </td>
                <td className="entry-btn-add">
                    <button onClick={() => {
                        cart.addItem(wine["@id"], wine.identifier, 1)
                    }} type="button" className="btn btn-secondary">+1pc.
                    </button>
                    <button onClick={() => {
                        cart.addItem(wine["@id"], wine.identifier, 6)
                    }} type="button" className="btn btn-secondary">+6pcs.
                    </button>
                </td>
                <td className="entry-padding"/>
            </tr>
        ))
    })

    return (
        <table className="wine-listing">
            <tbody>
            {entries}
            </tbody>
        </table>
    )

}

const CatalogPage: FC = () => {
    const {busy, error, catalog} = useCatalog()
    const {key, user} = useContext(AuthStateContext)
    const {id} = useParams<{ id: string }>()
    const [idList, setIdList] = useState<string[]>([])
    const [recently, setRecently] = useState<string[]>([])
    const [country, setCountry] = useState<TypeCountry>()

    useEffect(() => {
        if (id === "recent")
            return
        ky.get(API_URL + "/documents/countries?filter[@id]=/" + id + "/")
            .json<{ "@graph": TypeCountry[] }>()
            .then(res => setCountry(res["@graph"][0]))
            .catch(err => console.log(err))
    }, [id])

    useEffect(() => {
        if (id !== "recent" || !user || !key)
            return
        ky.get(`${HAPI_URL}/accounts/${encodeBase64(user?.email || "")}/recently-ordered`,
            {"headers": {"Authorization": "Bearer " + key}})
            .json<{ ids: string[] }>()
            .then(resp => setRecently(resp.ids))
            .catch(err => console.log(err))
    }, [id, key, user])

    useEffect(() => {
        if (id !== "recent" || busy || !catalog.wines)
            return
        const firstKey = Object.keys(catalog.wines)[0]
        const sid = catalog.wines[firstKey][0].length
        const trimmed = recently.map(r => r.substring(r.length - sid))
        setIdList(trimmed)
    }, [recently, id])

    useEffect(() => {
        if (id === "recent")
            return
        if (busy || error || id === undefined)
            return
        const keyLength = Object.keys(catalog.wines)[0].length
        const sid = id.substring(id.length - keyLength)
        const catalogItems = catalog.wines[sid] === undefined ? [] : catalog.wines[sid]
        setIdList(catalogItems)
    }, [catalog, busy, error, id])

    if (error)
        return <div>An error has occurred</div>

    return (
        <div id="catalogPage">
            <div className="listing-container">
                {!busy ? (id === "recent" ? (
                    <>
                        <div className="country-header">Recently ordered</div>
                        <WineList idList={idList}/>
                    </>
                ) : (
                    <>
                        <div className="country-header">
                            {country ? (
                                <>
                                    <span className="country-icon"
                                          style={{"backgroundImage": "url(\"/assets/countries/" + country.identifier + ".svg\")"}}></span>
                                    {country.name}
                                </>
                            ) : "..."}
                        </div>
                        <WineList idList={idList}/>
                    </>
                )) : (
                    <div className="country-header">...</div>
                )}
            </div>
            <CartModule editable={true} hasCheckout={true}/>
        </div>
    )
}

export default CatalogPage